.Post {
    height: fit-content;
    width: 100%;
    padding: 20px 10px;
    // border: 1px solid black;
    // box-shadow: 0px 2px 2px 2px #ccc;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 0 !important;
    
    @media screen and (max-width: 700px) {
        padding: 20px;
        width: calc(100% - 40px);
    }
}

.PostHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .PostDetails {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
    }

    .PostDetails .Username {
        font-size: 20px;
        font-weight: 500;
    }

    .PostDetails .PostDate {
        font-size: 12px;
        font-weight: 500;
    }
}

.PostBody p {
    line-height: 20px;
}

.PostFooter {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.ProfileImage {
    width: 50px;
    height: 50px;
    /* border: 1
px
 solid; */
    border-radius: 50%;
    z-index: 1;
    overflow: hidden;
    box-shadow: 0px 3px 11px 0px #ccc;

    img {
        width: 50px;
        height: 55px;
    }
}

.PostDetails {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        font-size: small;
    }
}

.PostImage {
    height: 300px;
    width: 300px;
}

.PostUserDetails {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}

.MenuButtonContainer {
    display: flex;
    align-items: center;
    padding: 0 20px 0 0;
    position: relative;
}

.DropdownMenu {
    display: none;
    position: absolute;
    padding: 10px 0;
    display: none;
    position: absolute;
    top: 30px;
    width: 110px;
    right: 0;
    border: 1px solid #bdbdbd;
    border-radius: 10px;
    background-color: #fff;

    .Menu {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 0 10px;
        padding: 5px 10px;
        cursor: pointer;
        
        &:hover {
            background: #ededed;
        }
        
        span {
            font-size: small;
        }
    }
}

.MenuButton {
      background: none;
      border: none;
      position: relative;
}

.MenuButton:hover {
      .DropdownMenu {
        display: block;
      }
}

.PostImageLoadingMessage {
    margin: 20px 0;
    font-size: small;
}