.Posts {
    width: 710px;
    overflow: auto;
    overflow-x: hidden;
    // padding: 0 10px 0 0;

    @media screen and (min-width: 701px) and (max-width: 850px) {
        width: 610px;
    }

    @media screen and (max-width: 700px) {
        // width: 100%;
        width: calc(100vh - 115px);
    }
}

.Message, .NextPageLoader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.NextPageLoader {
    height: unset !important;
    padding-bottom: 20px;
}