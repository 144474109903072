.Page {}

// .row {
//     width: 100%;
//     height: calc(100vh - 57px);
//     display: flex;
//     justify-content: center;
//     gap: 40px;
// }

// .col-sidebar {
//     height: 100%;
//     width: 55px;
// }

// .col {
//     height: 100%;
//     width: 700px;
// }

.row {
    width: 100%;
    height: calc(100vh - 57px);
    display: flex;
    justify-content: center;
    gap: 40px;

    @media screen and (max-width: 700px) {
        height: calc(100vh - 115px);
    }
}

.col-sidebar {
    height: 100%;
    width: 55px;
}

.col {
    height: 100%;
    width: 700px;
}