@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
*{-webkit-tap-highlight-color:rgba(255,255,255,0)}body{margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}.sidebar-icon{width:30px}.CropContainerIcon{color:black;cursor:pointer;z-index:10000;position:absolute;top:10px}

.App-logo{height:40vmin;pointer-events:none}@media (prefers-reduced-motion: no-preference){.App-logo{animation:App-logo-spin infinite 20s linear}}.App-header{background-color:#282c34;min-height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:calc(10px + 2vmin);color:white}.App-link{color:#61dafb}@keyframes App-logo-spin{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}

.Nav_Nav__1JSmy{height:55px;display:flex;place-items:center;padding:0 20px;border-bottom:2px solid #ccc;box-shadow:-20px 0px 6px 0px #ccc;grid-gap:15px;gap:15px;font-family:'Bebas Neue', cursive}.Nav_Nav__1JSmy span{font-size:23px}.Nav_logo__3XXlT{width:40px;height:40px}

.Page_row__35DtZ{width:100%;height:calc(100vh - 57px);display:flex;justify-content:center;grid-gap:40px;gap:40px}@media screen and (max-width: 700px){.Page_row__35DtZ{height:calc(100vh - 115px)}}.Page_col-sidebar__hWIdf{height:100%;width:55px}.Page_col__3X_e3{height:100%;width:700px}

.Home_row__1hWxg{width:100%;height:calc(100vh - 57px);display:flex;justify-content:center;grid-gap:40px;gap:40px}@media screen and (max-width: 700px){.Home_row__1hWxg{height:calc(100vh - 115px)}}.Home_col-sidebar__3iRYO{height:100%;width:55px}.Home_col__1mYz0{height:100%;width:700px}.Home_Post_192__2hkVV{width:710px}@media screen and (min-width: 701px) and (max-width: 850px){.Home_Post_192__2hkVV{width:610px}}@media screen and (max-width: 700px){.Home_Post_192__2hkVV{width:100%}}.Home_Message__2jMFJ{display:flex;justify-content:center;align-items:center;height:100%}

.Sidebar_Sidebar__33SN-{width:50px;padding-top:25px}@media screen and (max-width: 700px){.Sidebar_Sidebar__33SN-{width:100%;height:30px;position:fixed;bottom:0;background:white;z-index:3;display:flex;justify-content:center;align-items:center;box-shadow:1px 12px 19px 5px #ccc;grid-gap:20%;gap:20%}}.Sidebar_SidebarIcon__352m_{cursor:pointer;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;margin-bottom:20px}

.Posts_Posts__27qcW{width:710px;overflow:auto;overflow-x:hidden}@media screen and (min-width: 701px) and (max-width: 850px){.Posts_Posts__27qcW{width:610px}}@media screen and (max-width: 700px){.Posts_Posts__27qcW{width:calc(100vh - 115px)}}.Posts_Message__3C-IX,.Posts_NextPageLoader__1Gsxg{height:100%;display:flex;justify-content:center;align-items:center}.Posts_NextPageLoader__1Gsxg{height:unset !important;padding-bottom:20px}

.Post{height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;width:100%;padding:20px 10px;border-bottom-left-radius:10px;border-bottom-right-radius:10px;border-top:0 !important}@media screen and (max-width: 700px){.Post{padding:20px;width:calc(100% - 40px)}}.PostHeader{display:flex;align-items:center;justify-content:space-between}.PostHeader .PostDetails{display:flex;flex-direction:column;align-items:flex-start;grid-gap:2px;gap:2px}.PostHeader .PostDetails .Username{font-size:20px;font-weight:500}.PostHeader .PostDetails .PostDate{font-size:12px;font-weight:500}.PostBody p{line-height:20px}.PostFooter{display:flex;flex-direction:row;grid-gap:10px;gap:10px}.ProfileImage{width:50px;height:50px;border-radius:50%;z-index:1;overflow:hidden;box-shadow:0px 3px 11px 0px #ccc}.ProfileImage img{width:50px;height:55px}.PostDetails{display:flex;justify-content:center;align-items:center}.PostDetails span{font-size:small}.PostImage{height:300px;width:300px}.PostUserDetails{display:flex;align-items:center;grid-gap:20px;grid-gap:20px;gap:20px}.MenuButtonContainer{display:flex;align-items:center;padding:0 20px 0 0;position:relative}.DropdownMenu{display:none;position:absolute;padding:10px 0;display:none;position:absolute;top:30px;width:110px;right:0;border:1px solid #bdbdbd;border-radius:10px;background-color:#fff}.DropdownMenu .Menu{display:flex;align-items:center;grid-gap:10px;gap:10px;padding:0 10px;padding:5px 10px;cursor:pointer}.DropdownMenu .Menu:hover{background:#ededed}.DropdownMenu .Menu span{font-size:small}.MenuButton{background:none;border:none;position:relative}.MenuButton:hover .DropdownMenu{display:block}.PostImageLoadingMessage{margin:20px 0;font-size:small}

.Login_Login__WctD9{height:calc(100vh - 57px)}.Login_Form__3sJQ1{grid-gap:20px;gap:20px}.Login_Form__3sJQ1,.Login_Login__WctD9{display:flex;justify-content:center;align-items:center;flex-direction:column}.Login_TextInput__25OPY,.Login_Input__2wN3N{width:300px;height:30px;border:1px solid #ccc;border-radius:5px;outline:none}.Login_TextInput__25OPY{width:280px !important;padding:0 10px}input[type=submit]{background:#ad65f9;color:white;cursor:pointer;border:0px solid #000}input[type=submit]:hover{border:2px solid #000}.Login_Link__27bMG{text-decoration:none;color:blue;font-size:small}.Login_error__1hpMK{color:red;font-size:small}.Login_Message__3iGrf{font-size:small}

.Register_Register__daDLY{height:calc(100vh - 57px)}.Register_Form__1elDp{grid-gap:20px;gap:20px}.Register_Form__1elDp,.Register_Register__daDLY{display:flex;justify-content:center;align-items:center;flex-direction:column}.Register_TextInput__1N3BQ,.Register_Input__2qm0V{width:300px;height:30px;border:1px solid #ccc;border-radius:5px;outline:none}.Register_TextInput__1N3BQ{width:280px !important;padding:0 10px}input[type=submit]{background:#ad65f9;color:white;cursor:pointer;border:0px solid #000}input[type=submit]:hover{border:2px solid #000}.Register_Link__lIu4z{text-decoration:none;color:blue;font-size:small}.Register_error__2XkXw{color:red;font-size:small}.Register_Message__S-XG0{font-size:small}

.NotFound_NotFound__DqFsV{display:flex;place-items:center;height:calc(100vh - 57px)}.NotFound_Container__Psmqu{display:flex;align-items:center;justify-content:center;flex-direction:column;grid-gap:70px;gap:70px;width:100%}.NotFound_Container__Psmqu img{width:30%}.NotFound_Container__Psmqu span{color:#626262}

.AddPost_AddPost__Ni8Ec{height:calc(100vh - 57px);display:flex;justify-content:center;align-items:center}.AddPost_Form__kilPg{grid-gap:20px;gap:20px}.AddPost_Form__kilPg,.AddPost_Login__3B4Tl{display:flex;justify-content:center;align-items:center;flex-direction:column}.AddPost_TextInput__2nIYd,.AddPost_Input__2qFNI{width:300px;border:1px solid #ccc;border-radius:5px;outline:none;font-family:sans-serif}input[type=text],input[type=submit]{height:30px}textarea{padding-top:5px !important}.AddPost_TextInput__2nIYd{width:280px !important;padding:0 10px}input[type=submit]{background:#ad65f9;color:white;cursor:pointer;border:0px solid #000}input[type=submit]:hover{border:2px solid #000}.AddPost_Link__1wSYN{text-decoration:none;color:blue;font-size:small}.AddPost_error__2XPGA{color:red;font-size:small}.AddPost_success__3_HAH{color:green;font-size:small}.AddPost_Message__2VaRB{font-size:small}.AddPost_FileInput__1QvNJ{display:none}.AddPost_LabelContainer__-ZTPj,.AddPost_LabelUploadButton__1CBsx{display:flex;justify-content:center;align-items:center;grid-gap:10px;gap:10px}.AddPost_LabelContainer__-ZTPj span,.AddPost_LabelUploadButton__1CBsx span{font-size:smaller}.AddPost_LabelContainer__-ZTPj .AddPost_FileName__36j_8,.AddPost_LabelUploadButton__1CBsx .AddPost_FileName__36j_8{text-overflow:ellipsis;max-width:195px;overflow:hidden;white-space:nowrap}.AddPost_LabelUploadButton__1CBsx{min-width:100px;cursor:pointer}.AddPost_cropper__nQ2ip{width:100vw;height:100vh;position:absolute;top:0;left:0}.AddPost_cropper__nQ2ip .AddPost_reactEasyCrop_Container__rKW7q{background-color:white}.AddPost_CropContainerIcon__3tn6w{color:black;cursor:pointer;z-index:10000;position:absolute;top:10px;right:10px}

.Me_Me__2T7nz{height:calc(100vh - 57px);display:flex;flex-direction:column;justify-content:center;align-items:center}.Me_Link__QeNrZ{text-decoration:none;color:blue;font-size:small}

