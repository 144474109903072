.Sidebar {
    width: 50px;
    padding-top: 25px;

    @media screen and (max-width: 700px) {
        width: 100%;
        height: 30px;
        position: fixed;
        bottom: 0;
        background: white;
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 1px 12px 19px 5px #ccc;//-3px -3px 19px 5px #ccc;
        gap: 20%;
    }
}

.SidebarIcon {
    cursor: pointer;
    width: fit-content;
    margin-bottom: 20px;
}